import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019',
    'Elvis Presley',
    'Tupelo, MS',
    'VISA ⠀•••• 3719',
    312.44,
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

function serializeHashtags(hashtags) {
  return hashtags.join(', ');
}

export default function Influencers( { searchResults } ) {
  return (
    <React.Fragment>
      <Title>Infuencers</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Followers</TableCell>
            <TableCell>Hashtags</TableCell>
            <TableCell>ROI</TableCell>
            <TableCell align="right">Estimated Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            searchResults.map((result) => (
              <TableRow key={result._id}>
                <TableCell>{result.username}</TableCell>
                <TableCell>{result.followers}</TableCell>
                <TableCell>{serializeHashtags(result.hashtags)}</TableCell>
                <TableCell>{1}</TableCell>
                <TableCell align="right">{`$${1}`}</TableCell>
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
