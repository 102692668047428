import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard'
import SavedSearch from './components/SavedSearch'
import SignUp from './components/SignUp'
import SignIn from './components/SignIn'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/explore" element={<Dashboard />} />
        <Route path="/savedSearch" element={<SavedSearch />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
