import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

export default function ListItems() {
  let navigate = useNavigate();
  const routeChange = (newPath) =>{
    navigate(newPath);
  }

  const handleLogout = () => {
    // Redirect to the home page after login
    routeChange('/signin');
  };

  return (<React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <ContentPasteSearchIcon />
      </ListItemIcon>
      <ListItemText primary="Explore" onClick={() => routeChange('/explore')} />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <SavedSearchIcon />
      </ListItemIcon>
      <ListItemText primary="Saved Search" onClick={() => routeChange('/savedSearch')} />
    </ListItemButton>
    <Divider sx={{ my: 1 }} />
    <ListItemButton>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" onClick={handleLogout} />
    </ListItemButton>
  </React.Fragment>
);
}
