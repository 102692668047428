import { useState } from "react";
import * as React from 'react';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

const platforms = [
  {
    value: 'TikTok',
    label: 'TikTok',
  },
  {
    value: 'Instagram',
    label: 'Instagram',
  },
  {
    value: 'Xiaohongshu',
    label: 'Xiaohongshu',
  },
];

export default function SearchBar({ searchResults, setSearchResults }) {
  const [hashtags, setHashtags] = useState([]);
  const [hashtag, setHashtag] = useState('');
  const [infoType, setInfoType] = useState('');
  const [info, setInfo] = useState('');
  const [searchSaved, setSearchSaved] = useState(false);

  const handleDelete = (itemToDelete) => {
    const updatedHashtags = hashtags.filter((item) => item !== itemToDelete);
    setHashtags(updatedHashtags);
  };

  const normalizeHashtag = (value) => {
    if (value.startsWith('#')) {
      return value;
    }
    return '#' + value;
  }

  const handleAlertOnClose = () => {
    setInfoType('');
    setInfo('');
  }

  const handleAddHashtag = () => {
    if (hashtag.trim().length === 0) {
      setInfoType('error');
      setInfo('Hashtag must not be empty')
      return;
    }
    const normalizedHashtag = normalizeHashtag(hashtag);
    let newHashtags = [...hashtags, normalizedHashtag];
    setHashtags(newHashtags);
    setHashtag('');
  }

  const handleSearch = async () => {
    if (hashtags.length === 0) {
      setInfoType('error');
      setInfo('You must provide at least one hastag');
      return;
    }
    try {
      const response = await fetch('/api/influencers', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ hashtags }) // Serialize the list as JSON
      });
      const data = await response.json();
      setSearchResults(data);
      setInfoType('success');
      setInfo('Successfully returned ' + data.length + ' results');
      setSearchSaved(false);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }

  const handleSave = async () => {
    if (searchResults.length === 0) {
      setInfoType('error');
      setInfo('Search results are empty, please make a query first');
      return;
    }

    if (searchSaved) {
      setInfoType('info');
      setInfo('Search results already saved, please make a new query');
      return;
    }

    try {
      const searchMetadata = {
        account_id : 1,
        platform : 'TikTok',
        context : hashtags.join(','),
      };
      const response = await fetch('/save/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ searchMetadata, searchResults })
      });
      const data = await response.json();
      if (response.ok) {
        setInfoType('success');
        setInfo('Search results successfully saved');
        setSearchSaved(true);
      } else {
        setInfoType('error');
        setInfo(data.error);
      }
    } catch (error) {
      setInfoType('error');
      setInfo(error.message);
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
            id="outlined-select-social-platform"
            select
            label="Social Platform"
            size="small"
            defaultValue="TikTok"
            helperText="Please select a social platform"
          >
          {platforms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="add-hashtag"
          className="text"
          onInput={(e) => {
            setHashtag(e.target.value);
          }}
          label="Enter a hashtag"
          variant="outlined"
          placeholder="#fashion"
          size="small"
          value={hashtag}
          helperText="Please add your hastag(s)"
        />
        <IconButton type="add" aria-label="add" onClick={handleAddHashtag}>
          <AddIcon style={{ fill: "blue" }} />
        </IconButton>
        <IconButton type="search" aria-label="search" onClick={handleSearch}>
          <SearchIcon style={{ fill: "blue" }} />
        </IconButton>
        <IconButton type="save" aria-label="save" onClick={handleSave}>
          <StarBorderIcon style={{ fill: "blue" }} />
        </IconButton>
      </Box>
      <Stack direction="row" spacing={1} m={1}>
        {
          hashtags.map((item, index) => (
            <Chip key={index} label={item} onDelete={() => handleDelete(item)} />
          ))
        }
      </Stack>
      <Stack direction="column" m={1} spacing={1}>
        {
          infoType === 'success' ? (
            <Alert severity="success" onClose={handleAlertOnClose}>{info}</Alert>
          )
          : null
        }
        {
          infoType === 'info' ? (
            <Alert severity="info" onClose={handleAlertOnClose}>{info}</Alert>
          )
          : null
        }
        {
          infoType === 'warning' ? (
            <Alert severity="warning" onClose={handleAlertOnClose}>{info}</Alert>
          )
          : null
        }
        {
          infoType === 'error' ? (
            <Alert severity="error" onClose={handleAlertOnClose}>{info}</Alert>
          )
          : null
        }
      </Stack>
    </React.Fragment>
  );
}
